<template>
  <div>
    <app-header />

    <main v-if="! loading">
      <!-- slideshow content begin -->
      <div class="uk-section section-home-banner" style="margin-bottom: 1rem">
        <div class="home-bg">
          <img
              :src="slider.background"
              alt="png"
              style="position: inherit; z-index: -1"
            />
        </div>
        <div class="home-caption">
          <div class="uk-container">
            <div class="uk-grid-large uk-flex-middle uk-grid uk-grid-stack">
              <div class="uk-width-1-2@m in-slide-text uk-first-column">
                <h1 class="uk-heading-small">{{ slider.title }}</h1>
                <p class="uk-text-lead">{{ slider.tagline }}</p>
                <p>
                  <router-link
                    :to="{ name: 'register' }"
                    class="uk-button uk-button-primary"
                    >Start Learning Now
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- slideshow content end -->

      <!-- section content begin -->
      <div class="uk-section uk-section-primary uk-preserve-color in-equity-1">
        <div class="uk-container">
          <div class="uk-grid">
            <div class="uk-width-1-1">
              <h4>Our Services</h4>
            </div>
          </div>
          <div
            class="
              uk-grid-match
              uk-grid-medium
              uk-child-width-1-4@m
              uk-child-width-1-2@s
              uk-margin-bottom
              uk-grid
            "
          >
            <div
              class="uk-first-column"
              v-for="(service, i) in services"
              :key="`service-${i}`"
            >
              <div
                class="uk-card uk-card-body uk-card-default uk-border-rounded"
              >
                <div class="uk-flex uk-flex-middle">
                  <span class="in-product-name red">{{ i + 1 }}</span>
                  <h5 class="uk-margin-remove">{{ service.title }}</h5>
                </div>
                <p>{{ service.content }} </p>
                <router-link
                  :to="{ name: 'public.signals' }"
                  class="
                    uk-button
                    uk-button-text
                    uk-float-right
                    uk-position-bottom-right
                  "
                  >Explore<i
                    class="fas fa-arrow-circle-right uk-margin-small-left"
                  ></i
                ></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- section content end -->

      <!-- section content begin -->
      <div
        class="
          uk-section
          in-equity-2
          uk-background-contain uk-background-center
        "
        style="background-image: url('/new/in-equity-2-bg.png')"
      >
        <div class="uk-container uk-margin-top">
          <div class="uk-grid uk-flex uk-flex-center">
            <div class="uk-width-2xlarge@m uk-text-center">
              <span class="uk-label uk-label-warning">
                {{ signals.badge }}
                <i
                  class="fa fa-arrow-right fa-xs uk-margin-small-left"
                ></i>
              </span>
              <h1 class="uk-margin-top">{{ signals.title }}</h1>
              <p class="uk-text-lead uk-margin-medium-top">{{ signals.tagline }}</p>
            </div>
            <div class="uk-width-2xlarge@m uk-margin-medium-top">
              <div
                class="
                  uk-grid
                  uk-child-width-1-4@m
                  uk-child-width-1-4@s
                  uk-text-center
                  in-feature-box
                "
              >
                <div
                  class="uk-first-column"
                  v-for="(item, i) in signals.items"
                  :key="`signals-item-${i}`"
                >
                  <router-link
                    :to="{ name: 'register' }"
                    class="uk-first-column"
                  >
                    <span class="in-icon-wrap">
                      <img
                          :src="item.icon"
                          alt="icon-1"
                          width="35"
                          height="42"
                        />
                    </span>
                    <p class="uk-margin-top">{{ item.content }}</p>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- section content end -->

      <!-- section content begin -->
      <div class="uk-section in-equity-3 in-offset-top-20">
        <div class="uk-container uk-margin-large-bottom">
          <div class="uk-grid uk-flex uk-flex-middle">
            <div class="uk-width-expand@m">
              <h1 class="uk-margin-small-bottom">{{ community.title }}</h1>
              <ul class="uk-list in-list-check">
                <li
                  v-for="(item, i) in community.items"
                  :key="`community-item-${i}`"
                >
                  {{ item }}
                </li>
              </ul>
              <router-link
                :to="{ name: 'register' }"
                class="uk-button uk-button-primary uk-button-large"
                >Sign Up<i
                  class="fas fa-arrow-circle-right uk-margin-small-left"
                ></i
              ></router-link>
            </div>
            <div
              class="uk-width-2xlarge uk-flex uk-flex-right uk-flex-center@s"
            >
              <div
                class="
                  uk-card uk-card-body uk-card-default uk-border-rounded
                  in-margin-top-60@s
                "
              >
                <div id="tradingview-widget">
                  <div style="width: 100%">
                    <table class="pricing-table">
                      <tbody>
                        <tr>
                          <td
                            style="
                              border-bottom: solid 1px #ddd;
                              border-right: solid 1px #ddd;
                            "
                          >
                            <h3>1 Month</h3>
                            <h1>
                              $ {{ membership_plans[1].whole }}
                              <small>.{{ membership_plans[1].cent }}</small>
                            </h1>
                          </td>
                          <td style="border-bottom: solid 1px #ddd">
                            <h3>3 Months</h3>
                            <h1>
                              $ {{ membership_plans[3].whole }}
                              <small>.{{ membership_plans[3].cent }}</small>
                            </h1>
                          </td>
                        </tr>
                        <tr>
                          <td style="border-right: solid 1px #ddd">
                            <h3>6 Months</h3>
                            <h1>
                              $ {{ membership_plans[6].whole }}
                              <small>.{{ membership_plans[6].cent }}</small>
                            </h1>
                          </td>
                          <td>
                            <h3>12 Months</h3>
                            <h1>
                              $ {{ membership_plans[12].whole }}
                              <small>.{{ membership_plans[12].cent }}</small>
                            </h1>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="
          uk-section uk-section-primary uk-preserve-color
          in-equity-6
          uk-background-contain uk-background-center
        "
        data-src="/new/in-equity-decor-2.svg"
        data-uk-img=""
        style="background-image: url('/new/in-equity-decor-2.svg')"
      >
        <div class="uk-container uk-margin-small-bottom">
          <div class="uk-grid uk-flex uk-flex-center">
            <div class="uk-width-3-4@m uk-margin-top">
              <div
                class="
                  uk-grid
                  uk-child-width-1-3@s
                  uk-child-width-1-3@m
                  uk-text-center
                  in-register
                "
              >
                <div class="uk-width-1-1 in-h-line uk-first-column">
                  <h2>{{ getting_started.title }}</h2>
                </div>
                <div class="uk-grid-margin uk-first-column" v-for="(item, i) in getting_started.items"
                  :key="`getting-starteditem-${i}`">
                  <span class="in-icon-wrap circle">{{ i + 1 }}</span>
                  <p>{{ item }}</p>
                </div>
                <div class="uk-width-1-1 uk-grid-margin uk-first-column">
                  <router-link
                    :to="{ name: 'register' }"
                    class="uk-button uk-button-text"
                    >Open account<i
                      class="fas fa-arrow-circle-right uk-margin-small-left"
                    ></i
                  ></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- section content end -->
    </main>

    <div v-if="loading" style="background: #fff; padding: 100px 0;">
      <loading />
    </div>

    <app-footer :disclaimer="disclaimer" />
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import AppFooter from "./components/AppFooter.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
  },

  mounted() {
    this.fetch();
  },

  data() {
    return {
      membership_plans: { 1: {}, 3: {}, 6: {}, 12: {} },
      slider: {},
      services: [],
      signals: {},
      community: {},
      getting_started: {},
      loading: true,
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios.get("/api/v1/home").then((response) => {
        this.membership_plans = response.data.membership_plans;
      });

      this.$axios.get("/api/v1/sitecontent/public-home").then((response) => {
        this.slider = response.data.slider;
        this.services = response.data.services;
        this.signals = response.data.signals;
        this.community = response.data.community;
        this.getting_started = response.data.getting_started;
        this.disclaimer = response.data.disclaimer;
        this.loading = false;
      });
    },
  },
};
</script>
